/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the page layout of Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the DefaultNavbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `name` key is used for the name of the route on the DefaultNavbar.
  3. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  4. The `icon` key is used for the icon of the route on the DefaultNavbar, you have to add a node.
  5. The `collapse` key is used for making a collapsible item on the DefaultNavbar that contains other routes inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  6. The `route` key is used to store the route location which is used for the react router.
  7. The `href` key is used to store the external links location.
*/

import ArgonBox from "base-components/ArgonBox";
import bsvLogo from "assets/images/bsv-logo.svg";
import btcLogo from "assets/images/btc-logo.png";
import mvcLogo from "assets/images/mvc-logo.jpg";


const pageRoutes = [
  {
    name: "Products",
    collapse: [
      {
        name: "Smart Contract",
        route: "/#smart-contract-section",
      },
      {
        name: "Tokens",
        route: "/#tokens-section",
      },
      {
        name: "Development Platform",
        route: "/#development-platform-section",
      },
    ],
  },
  {
    name: "Chains",
    collapse: [
      {
        name: "BTC",
        href: "https://docs.scrypt.io/open-api/btc",
        // description: "Bitcoin is an innovative payment network and a new kind of money.",
        icon: <ArgonBox component="img" src={btcLogo} width={30}  />,
      },
      {
        name: "BSV",
        href: "https://docs.scrypt.io/",
        // description: 
        // <>
        //   {"The original Bitcoin, as launched in 2009, is committed to unbounded scaling, nanopayments,"}
        //   <br></br>
        //   {"and full compliance with all existing laws."}
        // </>,
        icon: <ArgonBox component="img" src={bsvLogo} width={30} />,
      }
      // },
      // {
      //   name: "MVC",
      //   href: "https://docs.scrypt.io/mvc",
      //   description: <>
      //     {"MVC is a revolutionary public blockchain integrated with multiple innovations."}
      //     <br></br>
      //     {"With the Layer 1 DID & Smart contract technology, MVC aims to become the Blockchain for Web3."}
      //   </>,
      //   icon: <ArgonBox component="img" src={mvcLogo} width={30} color="info" className="ni ni-planet" />,
      // },
    ],
  },
  {
    name: "Docs",
    href: "https://docs.scrypt.io",
  },
  {
    name: "Resources",
    collapse: [
      {
        name: "Etch/Mint Runes",
        href: "https://runes.scrypt.io/",
      },
      {
        name: "Inscribe 1SatOrdinals",
        href: "https://inscribe.scrypt.io",
      },
      {
        name: "Academy",
        href: "https://academy.scrypt.io",
      },
      {
        name: "By Example",
        href: "https://by-example.scrypt.io/",
      },
      {
        name: "Awesome",
        href: "https://github.com/sCrypt-Inc/awesome-scrypt/",
      },
      {
        name: "Faucet",
        route: "/faucet",
      },
      {
        name: "Youtube",
        href: "https://www.youtube.com/channel/UCzQsmR836zW8b1kh03Ht04w",
      },
      {
        name: "Example Contracts",
        href: "https://github.com/sCrypt-Inc/boilerplate",
      },
    ],
  },
  {
    name: "Pricing",
    route: "/pricing",
  },
  {
    name: "Community",
    collapse: [
      {
        name: "Slack",
        href: "https://join.slack.com/t/scryptworkspace/shared_invite/enQtNzQ1OTMyNDk1ODU3LTJmYjE5MGNmNDZhYmYxZWM4ZGY2MTczM2NiNTIxYmFhNTVjNjE5MGYwY2UwNDYxMTQyNGU2NmFkNTY5MmI1MWM",
      },
      {
        name: "Telegram",
        href: "https://t.me/joinchat/GwaRAxKT16JjXyHt5PuhHw",
      },
    ],
  },
];

export default pageRoutes;
